// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: 1rem 0;
  height: auto;
}
.alignleft,
.alignright {
  margin: 0 0 1rem;
  height: auto;
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: 1rem;
  }
  .alignright {
    float: right;
    margin-left: 1rem;
  }
}

// Captions
.wp-caption {

}
.wp-caption-text {

}

// Text meant only for screen readers

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}
