$px15 : .9375rem;
$baseFontsize: 16px;

body{
  font-family: "proxima-nova", "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  font-size: $baseFontsize;
  line-height: .75rem;
}
body, input, textarea, select, button{
  //text-rendering: optimizeLegibility;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //-moz-font-feature-settings: 'liga', 'kern';
}
input, textarea, select, button{
  font-family: inherit;
}

h1,h2,h3,h4,h5,h6,p,table,ul,ol{
  margin-top: 0;
  line-height: 1.2rem;
  margin-bottom: 1.125rem;
  font-weight: 500;
  .article-content &{
    margin-bottom: 0.3rem;
  }
}
b,strong{
  font-weight: 700;
}
input, textarea, select, button{
  line-height: 1.1rem;
}
h1,h2,h3,h4,h5,h6, .site-navigation, button, .button, .media__caption, button, .list--credits, .sub-menu .menu-image-title, .entry-meta{
  font-family: 'brandon-grotesque',Helvetica,sans-serif;
}
h2,h3,h4,h5,h6, .media__caption, button, .button, .entry-meta{
  font-weight: 700;
}
h1,h2,h3,h4,h5,h6{
  a{
    color: inherit !important;
  }
}
h1 {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  @media #{$medium-up}{
    font-size: 1.875rem;
    .blog &{
      font-size: 1rem;
    }
  }
}
p.ingress{
  font-family: "le-monde-livre-std", Georgia,serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.entry-meta{
  letter-spacing: 1px;
}
.page-header, .section-header h2{
  letter-spacing: 0.5rem;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9375rem;
  .page-template-template-article &{
    font-size: 1.625rem;
    font-family: "le-monde-livre-std", Georgia,serif;
    text-transform: none;
    letter-spacing: 1px;
    @media #{$medium-up}{
      font-size: 2.5rem;
    }
  }
  @media #{$small-only}{
    text-align: left !important;
    padding-left: 0.625rem;
    font-size: 0.625rem;
    letter-spacing: 0.18rem;
  }
}
.page-header{
  @media #{$medium-up}{
    font-size: 1.875rem;
    font-weight: 300;
  }
}
.header--product__after-image{
  .article-header{
    margin-bottom: 1.3rem;
  }
  .page-header{
    @media #{$small-only}{
      text-align: left !important;
      padding-left: 0.625rem;
      font-size: 0.625rem;
      letter-spacing: 0.18rem;
    }
  }
}
.popular-products--text{
  h3{
    font-weight: 900;
    letter-spacing: 1px
  }
}

@media #{$small-only}{
  .site-navigation__item{
    font-size: 1.8rem;
    line-height: .8;
    font-family: "le-monde-livre-std",Georgia,serif;
    text-transform: none;
  }
  .article-text__product blockquote{
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1.3rem;
    margin-bottom: 1.3rem;
    margin-right: 0;
    p {
    font-size: 1.3rem;
    line-height: 1.6;
    }
  }
}
.entry-title{
  font-family: "le-monde-livre-std", Georgia,serif;
  font-size: 1.625rem;
  line-height: 1;
  text-transform: none;
  @media #{$medium-up}{
    font-size: 2.5rem;
  }
}
h2 {
  font-size: 1rem;
}
.blog-teaser--title{
  font-size: 1.25rem;
  line-height: 1;
  color: $colorLight;
  @media #{$small-only}{
    font-size: 1.125rem;
    line-height: 1.25;
  }
  @media #{$medium-up}{
    font-size: 1.2rem;
    line-height: 1.35;
  }
  @media #{$large-up}{
    font-size: 1.5rem;
  }
  /*
  @media #{$small-only}{}
  $small-only: "#{$screen} and (max-width: 47.9375rem)" !default;
  $small-down: "#{$screen} and (max-width: 37.5rem)" !default;
  $small-up: "#{$screen} and (min-width: 37.5rem)" !default;
  $medium-up: "#{$screen} and (min-width: 48rem)" !default;
  */
}

h3 {
  font-size: 0.8125rem;
  text-transform: uppercase;
  margin-top: 1.65rem;
  margin-bottom: .55rem;
  .article-content &{
    margin-top: .6rem;
    &:first-child{
      margin-top: 0;
    }
  }
}

h4{
  font-size: 0.8125rem;
}
.media__caption,.sub-menu .menu-image-title{
  font-size: 0.625rem;
  letter-spacing: 2.2px;
  @media #{$medium-up}{
    font-size: 0.625rem;
  }
}
.product-groups h4{
  text-transform: uppercase;
}
.media__caption, .list--credits{
  text-transform: uppercase;
}
h3 + p{
  margin-top: 0;
}
p, ul, ol, pre, table, address{
  font-size: 0.8125rem;
}
button, .button{
  font-size: 0.875rem;
  letter-spacing: 2px;
}
input, textarea, select, button{
  font-size: 1rem;
}
.list--credits{
  font-size: .6875rem;
}
blockquote{
  margin-top: 0;
  margin-left: 0;
}
blockquote p, .blog-posts--list__link{
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "le-monde-livre-std", Georgia,serif;
  margin-bottom: 0;
}
#menu-primary-menu > li > a{
  @media #{$medium-up}{
    letter-spacing: 0.15rem;
  }
}
.site-navigation ul{
  font-weight: 500;
  font-size: 0.75rem;
}

address{
  font-style: normal;
}
a:link,a:visited{
  text-decoration: none;
  color:$colorDarkText;
}
a:hover{
  text-decoration: underline;
  color:$colorGold;
}
a:active{
  text-decoration: underline;
  color:$colorGold;
}
.container--invert a:link,
.container--invert a:visited,
.container--invert a:hover,
.container--invert a:active{
  color:$colorLight;
}
.article-text{
  a{
    color:$colorGold;
  }
}
.text-center{
  text-align: center;
}
.sub-menu .menu-image-title{
  font-weight: 700;
  color:$colorDarkText;
}
@media #{$medium-up}{
  blockquote p, .blog-posts--list__link{
    font-size: 1.4375rem;
    line-height: 2.25rem;
    margin-bottom: 0;
  }
}
