@media #{$small-only}{
  .touch-swipe--wrap{
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .touch-swipe{
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling:touch;
    padding-bottom: 20px;
    margin-bottom: -40px;
    .thumb-grid--item__instagram{
      margin-left: 30px;
    }
    .thumb-grid--item__instagram + .thumb-grid--item__instagram{
      margin-left: 0;
    }
  }
  .touch-swipe--item{
    width: 75%;
    padding-left: 0;
    padding-right: 0;
    margin-right: 20px;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    float: none;
    &:last-child{
      margin-right: 0;
    }
    &.thumb-grid--item__instagram:last-child{
      margin-right: 30px;
    }
  }

}
