
*, *:before, *:after{
  box-sizing: border-box;
}
body,html{
  margin: 0;
  padding: 0;
}
.container--invert{
  background-color: $colorDarkBg;
}
.container--invert *{
  color:$colorLight;
}
