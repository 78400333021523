// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

// gravity forms
.gform_anchor{
  display: none;
}
.gform_wrapper{
  min-height: 345px;
}
.gf_page_steps{
  margin-bottom: 2.3rem;
  @extend .row;
}
.gf_step{
  margin-top: -12px;
  @extend .col;
  @extend .col-sm-6;
}
.gf_step_number{
  display: none;
}
.gf_step_label{
  @extend .button;
  @extend .button--wide;
  cursor: auto;
  color: $colorGold !important;
  &:hover{
    background: $colorDarkBg !important;
    color: $colorGold !important;
  }
}
.gf_step_active .gf_step_label{
  @extend .active;
  color: $colorLight !important;
  &:hover{
    background: $colorGrey !important;
    color: $colorLight !important;
  }
}

.gform_page_fields{
  margin-top: 2.3rem;
}
.gform_page_footer{
  overflow: hidden;
  margin-top: 2.3rem;
  margin-bottom: 2.3rem;
  [type="submit"]{
    float: right;
  }
}
.gform_next_button{
  float: right;
}

.gform_fields{
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
}
.gfield_radio{
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  li{
    float: left;
    margin-right: 1rem;
  }
}
.gfield_label{
  display: block;
  margin-bottom: .25rem;
  font-weight: 700;
}
.gfield{
  margin-bottom: 1rem;
}
.gfield_error {
  .validation_message, .gfield_label{
    color: $colorRed;
  }
  *{
    border-color: $colorRed !important;
  }
}

.validation_error{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border:2px solid $colorLightGrey;
  text-align: center;
  padding:1rem;
}

.ginput_container{
  input[type="email"],
  input[type="number"],
  input[type="text"],
  textarea,
  select{
    display: block;
    width: 100%;
    padding: 0.5rem;
    border:1px solid $colorGrey;
    background-color: $colorLight;
    border-radius: 0;
    background: white;
    box-shadow: none;
    -webkit-appearance: none;
  }
  select{
    padding:0.5rem;
    -webkit-appearance:none;
  }
}
.webkit-appearance{
  .ginput_container_select{
    position: relative;
    &:after{
      position: absolute;
      display: block;
      top:12px;
      right: .5rem;
      content: '';
      width: 0;
      height: 0;
      border-left:7px solid transparent;
      border-right:7px solid transparent;
      border-top:10px solid $colorDarkBg;
    }
  }
}
.ginput_container_radio{
  overflow: hidden;

}
