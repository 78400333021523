$col-padding:  .625rem;
/* grid */
.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 60rem;
}
.row-wide{
  max-width: none;
}
.row:before, .row:after,
.cf:before, .cf:after{
  content: "";
  display: table;
}
.row:after,.cf:after{
  clear: both;
}
.row .row {
  width: auto;
  margin-left: -$col-padding;
  margin-right: -$col-padding;
  margin-top: 0;
  margin-bottom: 0;
  max-width: none;
}
.row .row:before, .row .row:after,
.row .row.collapse:before, .row .row.collapse:after {
  content: " ";
  display: table;
}
.row .row:after {
  clear: both;
}
.row .row.collapse {
  width: auto;
  margin: 0;
  max-width: none;
}
.row .row.collapse:after {
  clear: both;
}

.col{
  padding-left: $col-padding;
  padding-right: $col-padding;
  float: left;
  width: 100%;
}
.col-sm-1{
  width:83.33333333%
}
.col-sm-2{
  width: 16.66666667%;
}
.col-sm-2-5{
  width: 20%;
  &.thumb-grid--item:nth-child(5n+1):before{
    display: none;
  }
}
.col-sm-3{
  width: 25%;
}
.col-sm-4{
  width: 33.333333333%;
}
.col-sm-5{
  width: 41.66666667%;
}
.col-sm-6{
  width: 50%;
}
.col-sm-7{
  width: 58.33333333%;
}
.col-sm-8{
  width: 66.666666667%;
}
.col-sm-9{
  width: 75%;
}
.col-sm-10{
  width: 83.33333333%;
}
.col-sm-11{
  width: 91.66666667%;
}
.col-sm-12{
  width: 100%;
}
.row.collapse > .col,
.row.collapse > .cols {
    padding-left: 0;
    padding-right: 0;
}
.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.thumb-grid--item{
  position: relative;
  background: $colorDarkBg;
  overflow: hidden;
  margin: 0;
  &:before, &:after{
    content: '';
    display: block;
    position: absolute;
    background: white;
    z-index: 2;
  }
  &:before{
    width: 1px;
    top: 0;
    bottom: 0;
    left: 0;
  }
  &:after{
    height: 1px;
    right: 0;
    bottom: 0;
    left: 0;
  }
  a.thumb--link{
    display: block;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    img{
      display: none;
    }
  }
}

@media #{$small-up}{

  .col-sm-up-1{
    width:83.33333333%
  }
  .col-sm-up-2{
    width: 16.66666667%;
  }
  .col-sm-up-2-5{
    width: 20%;
  }
  .col-sm-up-3{
    width: 25%;
  }
  .col-sm-up-4{
    width: 33.333333333%;
  }
  .col-sm-up-5{
    width: 41.66666667%;
  }
  .col-sm-up-6{
    width: 50%;
    &.thumb-grid--item:nth-child(2n+1):before{
      display: none;
    }
  }
  .col-sm-up-7{
    width: 58.33333333%;
  }
  .col-sm-up-8{
    width: 66.666666667%;
  }
  .col-sm-up-9{
    width: 75%;
  }
  .col-sm-up-10{
    width: 83.33333333%;
  }
  .col-sm-up-11{
    width: 91.66666667%;
  }
  .col-sm-up-12{
    width: 100%;
  }
  .col-sm-up-dividers{
    position: relative;
    .row{
      position: relative;
    }
  }
  .col-sm-up-dividers .col:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 1.3rem;
    width: 1px;
    background-color: #ebebeb;
    transform:translateX(-0.625rem);
  }
  .col-sm-up-dividers .col:first-child:before{
    display: none;
  }
}
@media #{$medium-up}{
  .col-md-up-1{
    width:83.33333333%
  }
  .col-md-up-2{
    width: 16.66666667%;
  }
  .col-md-up-2-5{
    width: 20%;
    &.thumb-grid--item:nth-child(2n+1):before{
      display: block;
    }
    &.thumb-grid--item:nth-child(5n+1):before, &:first-child:before{
      display: none;
    }

  }
  .col-md-up-3{
    width: 25%;
    &.thumb-grid--item:nth-child(2n+1):before{
      display: block;
    }
    &.thumb-grid--item:nth-child(4n+1):before, &:first-child:before{
      display: none;
    }
  }
  .col-md-up-4{
    width: 33.333333333%;
  }
  .col-md-up-5{
    width: 41.66666667%;
  }
  .col-md-up-6{
    width: 50%;
  }
  .col-md-up-7{
    width: 58.33333333%;
  }
  .col-md-up-8{
    width: 66.666666667%;
  }
  .col-md-up-9{
    width: 75%;
  }
  .col-md-up-10{
    width: 83.33333333%;
  }
  .col-md-up-11{
    width: 91.66666667%;
  }
  .col-md-up-12{
    width: 100%;
  }
  .col-md-up-dividers{
    position: relative;
    .row{
      position: relative;
    }
  }
  .col-md-up-dividers .col:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    transform:translateX(-0.625rem);
    bottom: 1.3rem;
    width: 1px;
    background-color: #ebebeb;
  }
  .article-text .col-md-up-dividers .col:before{
    transform:translateX(-1.25rem);
    bottom: 0.3rem;
  }
  .col-md-up-dividers .col:first-child:before{
    display: none;
  }
  .col--single-content{
    padding-left: 3.3rem;
    float: right;
  }
}
@media screen and (min-width:700px) {

  .site-footer .some-navigation__list{
    margin-bottom: 2rem;
  }
  .site-footer--logo{
    margin-bottom: 1rem;
  }
}

@media #{$small-only}{
  .header--product__after-image .page-header{
    padding-left: 30px;
    text-align: left;
  }
  .article-text__product{
    padding-left: 20px;
    padding-right: 20px;
  }
  .completed-products{
    .section-header{
      h2{
        margin: 0;
        text-align: left;
        padding-left: 30px;
      }
    }
  }
  .popular-products{
    background-color: $colorDarkBg;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .section-header{
      h2{
        margin: 0;
        text-align: left;
        padding-left: 30px;
        color: $colorLight;
        font-weight: normal;
      }
    }
  }
  .popular-products__list--wrapper{
    overflow: hidden;
    padding-top: 20px;
    .row{
      width: auto;
    }
  }
  .popular-products__list{
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding-bottom: 20px;
    margin-bottom: -20px;


    &:before{
      display: none;
    }
    .col{
      padding-left: 0;
      padding-right: 0;
      margin-right: 20px;
      background-color: $colorLightGrey;
      white-space: normal;
      display: inline-block;
      vertical-align: top;
      float: none;
      width: 75%;
      &:before{
        display: none;
      }
      &:first-child{
        margin-left: 30px;
      }
      &:last-child{
        margin-right: 30px;
      }
    }
  }
}
