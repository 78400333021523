.button, .button a{
  background: $colorDarkBg;
  display: inline-block;
  padding: 1rem 1.5rem;
  color: $colorLight;
  border:none;
  border-radius: 3px;
  text-decoration: none !important;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s linear;
  -webkit-appearance:none;
  line-height: 1;
  outline:none;
  .no-touchevents &{
    &:hover{
      background-color: $colorGold !important;
      color: $colorDarkText !important;
    }
  }
}
.button:link, .button a:link,
.button:visited, .button a:visited,
.button:active, .button a:active{
  color: $colorLight;
}
.sub-navigation__item .button{
  background-color: #dbdbdb;
}

.active.button, .active .button a,
.active.button:link, .active .button:link, .active .button a:link,
.active.button:visited, .active .button:visited, .active .button a:visited,
.active.button:active, .active .button:active, .active .button a:active{
  color: $colorLight;
  background-color: $colorActiveButtonBg;
}
.button--inverse:link,
.button--inverse:visited,
.button--inverse:active{
  color: $colorDarkText !important;
  background-color: $colorLight !important;
  .no-touchevents &{
    &:hover{
      background-color: $colorGold !important;
      color: $colorDarkText !important;
    }
  }
}
.button--wider{
  padding-left: 6rem;
  padding-right: 6rem;
  max-width: 100%;
}
.button--wide{
  display: block;
}
@media #{$medium-up}{
  .button-md-up--wider{
    padding-left: 6rem;
    padding-right: 6rem;
    max-width: 100%;
  }
}
