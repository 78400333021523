@import "common/_variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
// endbower

@import "common/_global";
@import "common/_type";
@import "common/_helpers";
@import "components/_buttons";
@import "components/_comments";
@import "components/_grid";
@import "components/_forms";
@import "components/_wp-classes";
@import "components/_hero";
@import "components/_touch-swipe";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";
@import "layouts/_tinymce";

html{
  min-height:100%;
}

body {
  font-size: $fontSize;
  color: #333;
  -webkit-overflow-scrolling: none;
}


.subpage-navigation{
  margin-bottom: 3.75rem;
  &.subpage-navigation--after-content{
    margin-bottom: 1.5625rem;
  }
  a{
    @extend .button;
    @extend .button--wide;
  }
}

.page-template-template-product .toggle-product-list a{
    color: $colorGold;
}


main.content{
  position: relative;
  background-color: #fff;
  z-index: 100;
  padding-top: 65px;
  .page-template-template-product &{
    @media #{$small-only}{
      padding-top: 50px;
    }
  }
  .home &{
    padding-top: 0;
  }
  .blog &{
    @media #{$medium-up}{
      margin-bottom: 7rem;
    }
  }
}
.frontpage-wrap{
  position: relative;
  height:100vh;
}

.article-header{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.article-header__company{
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.product--header{
  position: absolute;
  left:10px;
  right:10px;
  z-index: $roof;
  background: $colorLight;
  padding: 70px 0 1px;
}

.blog-posts--list__item{
  display: block;
  vertical-align: middle;
  margin-bottom: 1.35rem;
}

.site-footer{
  position: relative;
  z-index: 2;
}

/* product lift list */
.product-list{
  list-style:none;
  margin: 0;
  padding: 0;
}
.product-gallery--list{
  list-style:none;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 3.75rem;
  @media #{$small-only}{
    margin-bottom: 0;
  }
}
.article-text{
  @media #{$small-only}{
    margin-bottom: 0.5625rem;
  }
}
.popular-products{
  margin-bottom: 2rem;
}


figure{
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  .entry-content & img{
    @extend .img-responsive;
  }
}
/**
* article
*/

.slider--article,
.article-text,
.swipe-gallery--precisethumbs{
  margin-bottom: 3.75rem;
}

.swipe-gallery--precisethumbs p{
    margin: 0;
}
.swipe-gallery--precisethumbs .row{
  padding-bottom: 35%;
  position: relative;
  margin-bottom: 1.25rem;
  + .row{
    margin-bottom: 0;
  }
}
.swipe-gallery--precisethumbs .row .col{
  position: absolute;
  height:100%;
}
.swipe-gallery--precisethumbs .row .col .half{
  height: 50%;
  border-bottom: .625rem solid $colorLight;
  a, figcaption{
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.swipe-gallery--precisethumbs .row .half + figure{
  position: absolute;
  top:auto;
  bottom: 0;
  left: 0;
  border-bottom: none;
  height:50%;
  width: 100%;
  border-top: .625rem solid $colorLight;
  border-bottom: 0;
  margin-bottom: 0;
}
.swipe-gallery--precisethumbs .row .col-last{
  right: 0;
}
.swipe-gallery--precisethumbs .row .col a{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: .625rem;
  bottom: 0;
  right: .625rem;
}
.swipe-gallery--precisethumbs .row{
  @media #{$small-only}{
    margin-bottom: .625rem;
    .col{
      &:first-child{
        a{
          right:0.3375rem;
        }
        figcaption{
          margin-right: 0.3375rem;
        }
      }
      &:last-child{
        a{
          left:0.3375rem;
        }
        figcaption{
          margin-left: 0.3375rem;
        }
      }
      .half{
        border-bottom-width: 0.3375rem;
        margin-bottom: 0.3375rem;
        margin-right: -0.3375rem;
        & + figure{
          border-top-width: 0.3375rem;
        }
        a{
          right: 0 !important;
        }
      }
    }
  }
}
.swipe-gallery--precisethumbs .row .col figcaption{
  margin-left: .625rem;
  margin-right: .625rem;
}
.unikea-product-wrap, .sub-menu-wrap{
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  width: 100%;
  background-color: $colorLight;
  overflow: hidden;
  @media #{$small-only}{
    padding-top: 0;
    padding-bottom: 0;
    .unikea-product-list{
      padding-top: 2.1875rem;
      padding-bottom: 3.125rem;
      margin-bottom: -15px;
    }
  }
}
.unikea-product-wrap{
  padding-bottom: 4.6875rem;
  .home &{
    padding-top: 4.6875rem;
    @media #{$small-only}{
      padding-top: 0;
    }
  }
  @media #{$small-only}{
    padding-bottom: 0;
  }
}
.sub-menu-wrap{
  width: auto;
  right: 10px;
}
.unikea-product-list, .sub-menu{
  list-style:none;
  margin: 0;
  padding: 0 10px;
  white-space: nowrap;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  @media #{$medium-up}{
    padding: 0 30px;
  }
}
.unikea-product-list::-webkit-scrollbar,
.sub-menu::-webkit-scrollbar {
  display: none;
}
.unikea-product-list__item, .sub-menu li{
  height: 100%;
  padding: 0 10px;
  display: inline-block;
  max-width: 250px;
  width:40%;
  position: relative;
}
.unikea-product-list__item + .unikea-product-list__item, .sub-menu li + li{
  &:before{
    content: '';
    display: block;
    border-left: 1px solid $colorGrey;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
}
.sub-menu img{
  @extend .img-responsive;
}
.sub-menu a{
  display: block;
}

.popular-products .popular-products--text{
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1.1rem;
}


.blog-posts{
  @media #{$small-only}{
  .section-header{
    padding-left: 20px;
  }
}
}
.blog-posts--list{
  margin: 0;
  padding: 0;
  list-style: none;
  @media #{$small-only}{
    padding-left: 20px;
    padding-right: 20px;
  }
}
.blog-posts--list__link{
  overflow: hidden;
  position: relative;
  &:after{
    margin-top: 0.625rem;
    margin-right: 10px;
    float: right;
    @extend .triangle-right;
  }
  &:hover:after{
    border-left-color: $colorGold;
  }
}


.slider{
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}
.touchevents{
  .frontpage-wrap main.content{
    position: absolute;
    bottom:0;
    width:100%;
  }
}
@media #{$small-only}{
  .frontpage-wrap main.content{
    position: absolute;
    bottom:0;
    width:100%;
  }
}


@media #{$medium-up}{
  html{
    border-left: 10px solid $colorDarkBg;
    border-right: 10px solid $colorDarkBg;
  }
  body:after {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      border-top: 10px solid $colorDarkBg;
      content: '';
      z-index: 100;
  }

  main.content{
    //padding-top: 1px;
  }
  .page-tuotteet main.content{
    padding-top: 65px;
  }
  .page-template-template-product main.content{
    padding-top: 100px;
  }
  .frontpage-wrap{
    .no-touchevents &{
      height:auto !important;
    }
  }
  /* article styles */
  .article-header{
    margin-top: 7rem;
    margin-bottom: 7rem;
    .page-template-template-article &{
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
    .blog &{
      margin-top: 0rem;
      margin-bottom: 3rem;
    }
  }
  .blog-posts--list__link .text{
    padding-top: .625rem;
  }
  .blog-posts--list__link .button {
      margin-left: 10px;
      float: right;
      overflow: hidden;
  }

  .unikea-product-list__item, .sub-menu li{
    width: 20%;
  }

}




.row-completed-products{
  background: $colorLight;
  margin-bottom: 2.8125rem;
}
.row--more-button{
  margin-bottom: 0.9375rem;
}
.blog-posts{
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.pswp__button, .pswp__button--arrow--left:before, .pswp__button--arrow--right:before{
  background-image: url('../images/default-skin.png');
}
.pswp__button--arrow--left, .pswp__button--arrow--right{
  background: none;
}
.pswp__preloader--active .pswp__preloader__icn {
    background-image: url('../images/preloader.gif');
}
.pre-product-list{
  .home &{
    hr{
      display: none;

    }
  }
}


@media #{$medium-up}{
  .article-content .col{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .article-content .col:first-child{
    padding-left: .625rem;
  }
}

.cookie-consent{
  display: none;
  .no-cookie-consent &{
    display: block;
  }
  box-shadow:$drop-shadow;
  background-color: $colorLight;
  position: absolute;
  z-index: $roof;
  top: 50px;
  padding-right: 2rem;
  @media #{$medium-up}{
    top: 70px;
    left: 10px;
    right: 10px;
  }
  p{
    padding: 1.125rem 1.5rem;
    margin: 0;
  }
  .ok{
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
[data-clickblock]{
  cursor: pointer;
}
