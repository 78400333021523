/* hero image*/
.hero-unit{
  height:100vh;
  position: relative;
}
.hero-unit .hero-img{
  position:fixed;
  .touchevents &{
    position: absolute;
  }
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media #{$medium-up}{
    left: 10px;
    right: 10px;
    .no-touchevents &{
      bottom:0 !important;
    }
    .touchevents &{
      left: 0;
      right: 0;
    }
  }
  .touchevents &{
    position: absolute;
  }
}
.page-yritys .hero-img{

}
.hero-img span{
  content: '';
  display: block;
  position: absolute;
  left:50%;
  top:0;
  height: 100%;
  background: 0 52% no-repeat;
  background-size: contain;
  width: 70%;
  max-width: 600px;
  transform: translateX(-50%);
}
@media #{$medium-up}{

  .hero-unit{
    padding-right: 10px;
  }

}
