.blog-menu{
  margin: 4rem 0 1.8rem;
  position: relative;

}
.open-blog-posts-archive{
  position: relative;
  z-index: 5;
}
.blog-posts--archive{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: $colorLight;
  z-index: 4;
  box-shadow: $drop-shadow;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  visibility: hidden;
  .blog-navigation-open &{
    opacity: 1;
    visibility: visible;
  }
}
.in-the-woods{
  width: 194px;
  float: left;
}
.open-menu--blog{
  display: block;
  float: right;
  padding-right: 0;
  margin-top: 6px;
}
.blog-teaser{
  @extend .thumb-grid--item;
  &:nth-child(2n+1){
    &:before{
      @media #{$small-only}{
        display: none;
      }
    }
  }
}
.blog-teaser--header{
  position: absolute;
  top:1rem;
  left: .5rem;
  right: .5rem;
  z-index: 1;
  @media #{$large-up}{
    top: 2rem;
    left: 1.2rem;
    right: 1.2rem;
  }
}

.blog-teaser--title{
  margin-bottom: 0;
  text-align: center;
  a{
    display: inline-block;
  }
  a:hover{
    text-decoration: none;
  }
}
.blog-teaser--figure{
  margin-bottom: 0;
  padding-bottom: 100%;
  height: 0;
  position: relative;
  &:before{
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2%);
  }
}
.blog-teaser--button{
  position: absolute;
  right: 0;
  bottom: 1rem;
  left: 0;
  text-align: center;
  @media #{$small-only}{
    display: none;
  }
  @media #{$small-up}{
    bottom: 1rem;
  }
  @media #{$large-up}{
    bottom: 2.5rem;
  }

}
.posts-navigation{
  text-align: center;
  margin:2.2rem 0;
  div{
    display: inline-block;
  }
  a{
    @extend .button;
    @extend .button--wider;
  }
}

.single-post{
  @media #{$small-only}{
    .swipe-gallery{
      padding-left: 0;
      padding-right: 0;
    }
    .touch-swipe--item{
      a{
        display: block;
        position: relative;
        padding-bottom: 100%;
        span{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      &:first-child{
        margin-left: 0.625rem;
      }
      &:last-child{
        margin-right: 0.625rem;
      }
    }
  }
}
