.col__footer--contact{
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
}
.site-footer--logo,.address--footer{
  margin-bottom: 3.375rem;
}
.address--footer{
  @media #{$small-only}{
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  a{
    text-decoration: underline;
  }

}

#contact{
  .button--wide{
    margin-left:4.75rem;
    margin-right:4.75rem;
  }
  @media #{$medium-up}{
    position: relative;
    .address--footer{
      margin-bottom: 1.5rem;
      span{
        padding-left: 0.625rem;
        padding-right: 0.625rem;
      }
    }
    .col__footer--contact{
      position: absolute;
      right: 0;
      height: 100%;
      .col__footer--contact-content{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .some-navigation__item{
    display: inline-block;
    a{
      display: inline-block;
      padding: 1rem;
    }
  }
  .site-footer .some-navigation__list{
    margin-bottom: 3.75rem;
  }

}

.list--credits{
  list-style: none;
  margin: 0 0 2rem;
  padding: 0;
  line-height: 1;
}
.list--credits li{
  display: inline-block;
  position: relative;
}
.list--credits li + li{
  margin-left: .5rem;
  padding-left: .5rem;
  &:before{
    content : '';
    display: block;
    position: absolute;
    border-left: 1px solid $colorLight;
    top: 1px;
    left:0;
    bottom: 2px;
  }
}
