/* header, logo */
.site-header{
  background-color: $colorDarkBg;
  height:50px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $roof + 1;
}
.site-header--logo{
  float: left;
  margin: 0;
  padding-top: 10px;
  padding-left: .625rem;
  position: relative;
  z-index: $roof;
}
.site-header--logo svg{
  height:30px;
  width: 30px;
}
.site-navigation__list, .some-navigation__list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.sub-navigation__list{
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}
.site-navigation{
  text-transform: uppercase;
}
.site-navigation a, .some-navigation a {
  color: $colorLight;
  transition: color .1s; /* autoprefixed ! */
  text-decoration: none;
  .no-touchevents &{
    &:hover {
      color: $colorGold;
    }
  }
}
.menu-primary-menu-container{
  overflow: hidden;
}
.site-navigation__item, .some-navigation__item{
  display: block;
  text-align: center;
  &.active a{
      color: $colorGold;
  }
}
.some-navigation__item path{
  fill: $colorLight;
}
.some-navigation__item:hover path{
  fill: $colorGold;
}
.hide-subs{
  .sub-menu-wrap{
  }
}
/* sub menu bar*/
.sub-menu-wrap{
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 10px;
  top: 70px;
  transition: opacity 0.2s ease-out;
  box-shadow: $drop-shadow;
}


/* menu open button */
.open-menu{
  display: block;
  width: 15px;
  height: 15px;
  padding:15px 0.625rem;
  cursor: pointer;
  position: relative;
}
.open-menu--header{
  position: absolute;
  right: 4px;
  top: 19px;
  z-index: $roof;
}
.open-menu span{
  width:15px;
  height:2px;
  background:$colorLight;
  display:block;
  position:absolute;
  border-radius:1px;
  left:0;
  transition:all 0.2s ease-in-out;
  &:nth-child(1){
    top: 0px;
  }
  &:nth-child(2){
    top: 4px;
  }
  &:nth-child(3){
    top: 8px;
  }
}
.open-menu--blog span{
  background-color: $colorDarkBg;
}
.site-navigation-open .open-menu--header span{
  &:nth-child(1){
    top:6px;
    transform:rotate(45deg);
  }
  &:nth-child(2){
    opacity:0;
  }
  &:nth-child(3){
    top:6px;
    transform:rotate(-45deg);
  }
}
.blog-navigation-open .open-menu--blog span{
  &:nth-child(1){
    top:6px;
    transform:rotate(45deg);
  }
  &:nth-child(2){
    opacity:0;
  }
  &:nth-child(3){
    top:6px;
    transform:rotate(-45deg);
  }
}
.logo-path {
  fill: $colorLight;
  animation-duration: .3s;
  animation-fill-mode: forwards;
}
.logo-path:hover {
 fill: $colorGold;
}
@media #{$small-only}{

  .site-navigation .active a{
    text-decoration: underline;
  }

  html.site-navigation-open{
    overflow: hidden !important;
  }
  html.site-navigation-open body{
    overflow: hidden !important;
  }
  html.site-navigation-open .content{
    /*filter: grayscale(50%);*/
  }
  .container-global-navigation{
    background: rgba(0,0,0,.85);
    background: $colorDarkBg;
    position:fixed;
    top: 0;
    right: 0;
    height:0;
    left: 0;
    padding-top: 50px;
    overflow: hidden;
    display: block;
    z-index: calc($roof) - 1;
    transition: height 0.25s linear;
    &:before{
      content:'';
      display: block;
      position: absolute;
      top: 50px;
      right: 0;
      left: 0;
      border-top: 1px solid #262626;
    }
  }
  .site-navigation-open .container-global-navigation{
    height:100%;
  }

  .some-navigation{
    display: none;
  }
  .site-navigation{
    display: table;
    min-height: 100%;
    height:100%;
    width: 100%;
  }
  .site-navigation > ul{
    display: table-cell;
    vertical-align: middle;
    list-style: none;
    margin:0;
    padding: 0;
    & li:first-child{
      margin-top: -25px;
    }
  }
  .site-navigation__item{
    opacity: 0;
    padding: 15px 0;
    margin: 0 25px;
    opacity: 0;
    transition: all 0.1s linear;
    transform: translateY(-10px);
  }
  .site-navigation__item:nth-child(1),
  .site-navigation__item:nth-child(2),
  .site-navigation__item:nth-child(3),
  .site-navigation__item:nth-child(4),
  .site-navigation__item:nth-child(5){
    transition-delay: 0;
  }
  .site-navigation-open .site-navigation__item:nth-child(1){
    transition-delay: 230ms;
  }
  .site-navigation-open .site-navigation__item:nth-child(2){
    transition-delay: 250ms;
  }
  .site-navigation-open .site-navigation__item:nth-child(3){
    transition-delay: 270ms;
  }
  .site-navigation-open .site-navigation__item:nth-child(4){
    transition-delay: 290ms;
  }
  .site-navigation-open .site-navigation__item:nth-child(5){
    transition-delay: 310ms;
  }
  .site-navigation-open .site-navigation__item{
    opacity: 1;
    transform: translateY(0);
  }
  .frontpage-wrap main.content{
    position: absolute;
    bottom:0;
    width:100%;
  }
}
@media #{$medium-up}{
  .toggle-submenu:hover{
    .sub-menu-wrap{
      visibility: visible;
      opacity: 1;
    }
  }
  .menu-item-has-children{
    > a{
      position: relative;
      padding-right: 15px;
      &:after{
        position: absolute;
        content: '';
        display: block;
        right: 0;
        top: 29px;
        height: 0;
        width: 0;
        border-top: 8px solid $colorLight;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
      &:hover{
        &:after{
          border-top-color: $colorGold;
        }
      }
    }
    &.active{
      a{
        &:after{
          border-top-color: $colorGold;
        }
      }
    }
    &.hide-subs{
      a{
        padding-right: 0;
        &:after{
          display: none;
        }
      }
    }
  }

  .site-header{
    height: 70px;
  }
  .site-header--logo{
    padding-top: 15px;
    left:15px;
  }
  .site-header--logo svg{
    height:36px;
    width: 36px;
  }
  .open-menu{
    display: none;
  }
  .site-navigation{
    float: right;
  }
  .some-navigation{
    padding-top: 27px;
    float: right;
  }
  #menu-primary-menu > li > a{
    display: block;
    height: 70px;
    padding-top: 27px;
  }
  .some-navigation{
    margin-right: 26px;
  }
  .site-navigation__item, .some-navigation__item{
    float:left;
    line-height: 1;
  }
  .site-footer .some-navigation__item{
    display: inline-block;
    float: none;
  }
  .site-navigation__item{
    padding-right: 10px;
    margin-right: 10px;
  }
  .site-navigation__item--divider-after{
    padding-right: 25px;
    margin-right: 25px;
    position: relative;
    &:after{
      position: absolute;
      display: block;
      content: '';
      border-right: 1px solid #2f2f2f;
      height:16px;
      right: 0;
      top: 25px;
    }
  }
  .some-navigation__item{
    padding-right: 14px;
    margin-right: 14px;
  }
  .some-navigation__item:last-child{
    margin-right: 0;
    padding-right: 0;
  }
}
@media #{$large-up}{
  .site-navigation__item{
    padding-right: 25px;
    margin-right: 25px;
  }
  .site-navigation__item--divider-after{
    padding-right: 50px;
    margin-right: 50px;
  }
}
