.img-responsive{
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}
.clear{
  clear: both;
}

hr{
    height: 0rem;
    border-left: 0;
    border-right: 0;
    border-bottom: none;
    border-top: 1px solid $hrColor;
    margin: 2.1875rem 0;
    &.hr--pre__popular{
      margin-bottom: 2.5rem;
    }
}
.hr--single{
  height: 0;
  border-bottom: 0;
}
.mb-0{
  margin-bottom: 0;
}
.mb-x1{
  margin-bottom: 1rem;
}
.mb-x2{
  margin-bottom: 2rem;
}
.mb-x3{
  margin-bottom: 3rem;
}
.mb-x4{
  margin-bottom: 4rem;
}
.mt-0{
  margin-top: 0;
}
.mt-x1{
  margin-top: 1.1rem;
}
.mt-x2{
  margin-top: 2.2rem;
}
.mt-x3{
  margin-top: 3.3rem;
}
.mt-x4{
  margin-top: 4.4rem;
}

.dot, .sub-menu-wrap a:after{
  display: inline-block;
  content: '';
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  border-radius: 50%;
  background-color: #0d0d0d;
}
.sub-menu-wrap a:after{
  margin-top: 1rem;
}
.dot.dot__active, .active > .dot, .sub-menu-wrap .active > a:after{
  background-color: #c19d75;
}
.triangle-right{
  display: inline-block;
  content: '';
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid;
  border-left-color: $colorDarkBg;
}
a:hover .triangle-right{
  border-left-color: $colorGold;
}
figure, .wp-caption{
  position: relative;
  overflow: hidden;
  /*display: inline-block;*/
  &.blog-teaser--figure, &.full-width{
    display: block;
  }
}
figure figcaption, .wp-caption .wp-caption-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $colorDarkBg;
    color: $colorLight;
    font-size: .8125rem;
    padding: 12px 15px;
    p{
      margin: 0;
    }
    transform: translate(0,100%);
    transition: transform .3s cubic-bezier(0.645, 0.045, 0.355, 1);
    .touchevents &{
      visibility: hidden;
    }
}
figure, .wp-caption{
    &:hover{
      figcaption, .wp-caption-text {
        transform:translate(0,0);
      }
    }
    .touchevents &{
      figcaption, .wp-caption-text {
        transform:translate(0,0);
      }
  }
}
/*
$small-only: "#{$screen} and (max-width: 47.9375rem)" !default;
$small-down: "#{$screen} and (max-width: 37.5rem)" !default;
$small-up: "#{$screen} and (min-width: 37.5rem)" !default;
$medium-up: "#{$screen} and (min-width: 48rem)" !default;

$medium: "#{$screen} and (min-width: 48rem) and (max-width: 64em)";
$large-up: "#{$screen} and (min-width: 64.063rem)";
$large: "#{$screen} and (min-width 64.063rem) and (max-width: 90rem)";
$xlarge-up: "#{$screen} and (min-width: 90.063rem)";
$xlarge: "#{$screen} and (min-width: 90.063rem) and (max-width: 120rem)";
$xxlarge-up: "#{$screen } and (min-width: 120.063rem)";
*/
.show-small-only{
  display: none;
}
@media #{$small-only}{
  .show-small-only{
    display: inherit;
  }
}
.hide-small{
  display: none;
}
.show-medium-up{
  display: none;
}
@media #{$medium-up}{
  .show-small-only{
    display: none;
  }
  .hide-small{
    display: inherit;
  }
  .show-medium-up{
    display: inherit;
  }
}
.visually-hidden{
  visibility: hidden;
}
.hide{
  display: none;
}



.ok{
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
}
.ok span{
  width:15px;
  height:3px;
  background:$colorDarkBg;
  display:block;
  position:absolute;
  border-radius:3px;
  left:0;
  transition:all 0.2s ease-in-out;
  &:nth-child(1){
    top: 0px;
  }
  &:nth-child(2){
    top: 9px;
  }
  &:nth-child(3){
    top: 18px;
  }
}
.ok span{
  &:nth-child(1){
    top:5px;
    transform:rotate(45deg);
  }
  &:nth-child(2){
    top:5px;
    transform:rotate(-45deg);
  }
}
